import { TCountryMode, User } from '@austria-codex/types'
import * as Sentry from '@sentry/react'
import { Config } from '../config'
import { LocalStorage, TLocalStorageKeys } from '../helpers/localstorage.helper'
import { MediaCategory } from '../types/media-item.types'
import { ETelemetrieEvents } from '../types/telemetrie'
import { uuid } from '../utilities/uuid.utility'

export type TEvent = {
  event_type: ETelemetrieEvents
  additional_information: Record<string, unknown> | null
  session_id: string
  source: string
  time: number
  user_id: string | null
}

type TOptions = {
  intervalInMilliSeconds?: number
  limit?: number
  url?: string
  userId?: string
  profileId?: string
  customerId?: string
  sectorId?: string
  specialisationId?: string
  mode?: TCountryMode
  licenses?: string[]
}

type TStartEventData = {
  appVersion: number
  userAgent: string | null
}

class Telemetrie {
  private _active = true

  // The localstorage key where data is stored
  private _eventsKey: TLocalStorageKeys = 'events'

  // Ms in which interval the sending script will be called
  private _intervalInMilliSeconds = 45 * 1000

  // Indicator if events are being sent right now
  private _isCurrentlySendingData = false

  // Max events which should be sent in a batch
  private _limit = 50

  // An unique uuid which represents a session
  private _session: string = uuid()

  // The id of the interval timer
  private _timerId: NodeJS.Timeout | null = null

  // The url to send the events to
  private _url: string = Config.telemetrie.url

  // User data
  private _userId: string | undefined
  private _profileId: string | undefined
  private _customerId: string | undefined
  private _sectorId: string | undefined
  private _specialisationId: string | undefined
  private _mode: TCountryMode = 'national'
  private _licenses: string[] | undefined

  constructor(options?: TOptions) {
    if (options?.intervalInMilliSeconds) {
      this._intervalInMilliSeconds = options.intervalInMilliSeconds
    }

    if (options?.limit) {
      this._limit = options.limit
    }

    if (options?.url) {
      this._url = options.url
    }

    if (options?.userId) {
      this._userId = options.userId
    }

    if (options?.profileId) {
      this._userId = options.profileId
    }

    if (options?.customerId) {
      this._userId = options.customerId
    }

    if (options?.sectorId) {
      this._sectorId = options.sectorId
    }

    if (options?.specialisationId) {
      this._specialisationId = options.specialisationId
    }

    if (options?.mode) {
      this._mode = options.mode
    }

    if (options?.licenses) {
      this._licenses = options.licenses
    }
  }

  public isActive(): boolean {
    return this._active
  }

  public deactivate() {
    this._active = false
  }

  // Get all events from local storage
  public getEvents(): Array<TEvent> {
    const eventsJson = LocalStorage.get(this._eventsKey)
    return eventsJson ? JSON.parse(eventsJson) : []
  }

  // Save events-array in local storage
  public setEvents(events: Array<TEvent>): void {
    LocalStorage.set(this._eventsKey, JSON.stringify(events))
  }

  public setUser(user: Partial<User>, mode: TCountryMode) {
    this._userId = user.id ?? this._userId
    this._profileId = user.profile ?? this._profileId
    this._customerId = user.customer ?? this._customerId
    this._sectorId = user.sectorId ?? this._sectorId
    this._specialisationId = user.specialisationId ?? this._specialisationId
    this._mode = mode
    this._licenses = user.licenses ?? this._licenses
  }

  // Create new session id (eg when user logs out)
  public resetSession() {
    this._session = uuid()
  }

  private addUserInfo(additionalData: Record<string, unknown> | undefined) {
    return {
      user: {
        profileId: this._profileId ?? null,
        customerId: this._customerId ?? null,
        sectorId: this._sectorId ?? null,
        specialisationId: this._specialisationId ?? null,
        mode: this._mode,
        licenses: this._licenses ?? null,
      },
      ...additionalData,
    }
  }

  // Processing and extending the event array
  private _dispatch(
    eventType: ETelemetrieEvents,
    additionalData?: Record<string, unknown>
  ): boolean {
    if (!this._active) {
      return false
    }

    additionalData = this.addUserInfo(additionalData)

    try {
      const events = this.getEvents()

      events.push({
        event_type: eventType,
        additional_information: additionalData ?? null,
        session_id: this._session,
        source: 'aco-frontend',
        time: Date.now(),
        user_id: this._userId ?? null,
      })

      this.setEvents(events)
    } catch (error) {
      Sentry.captureException(error)
      return false
    }

    return true
  }

  public start(data: TStartEventData): boolean {
    return this._dispatch(ETelemetrieEvents.AcoStart, data)
  }

  public searchSubstanceClicked(
    substanceId: string,
    substanceName: string
  ): boolean {
    return this._dispatch(ETelemetrieEvents.AcoSerachSubstanceClicked, {
      substanceId,
      substanceName,
    })
  }

  public articleClicked(
    type: 'oeaz',
    id: number,
    title: string,
    forSubstanceId: string,
    publicationDate: string,
    rubrik: MediaCategory,
    relevance: string,
    searchedSubstances: { id: string; name: string }[],
    searchedProducts: { id: string; name: string }[]
  ): boolean {
    return this._dispatch(ETelemetrieEvents.AcoMediaArticleClicked, {
      type,
      id,
      title,
      forSubstanceId,
      publicationDate,
      rubrik,
      relevance,
      searchedSubstances,
      searchedProducts,
    })
  }

  public searchDrugClicked(drugId: string, drugName: string): boolean {
    return this._dispatch(ETelemetrieEvents.AcoSerachDrugClicked, {
      drugId,
      drugName,
    })
  }

  public substanceClicked(
    currentDrugId: string,
    currentDrugName: string,
    substanceId: string,
    substanceName: string
  ): boolean {
    return this._dispatch(ETelemetrieEvents.AcoSubstanceClicked, {
      currentDrugId,
      currentDrugName,
      substanceId,
      substanceName,
    })
  }

  public drugClicked(
    drugId: string,
    drugName: string,
    currentDrugId: string | null,
    currentDrugName: string | null,
    currentSubstanceId: string | null,
    currentSubstanceName: string | null
  ): boolean {
    return this._dispatch(ETelemetrieEvents.AcoDrugClicked, {
      currentDrugId,
      currentDrugName,
      drugId,
      drugName,
      currentSubstanceId,
      currentSubstanceName,
    })
  }

  public mainTabClicked(tab: string) {
    return this._dispatch(ETelemetrieEvents.AcoMainTabClicked, {
      tab,
    })
  }

  // Start the interval (will be called at app start)
  public startTimer() {
    if (!this._active) {
      return
    }

    this._timerId = setInterval(this.sendEvents, this._intervalInMilliSeconds)
    // Trigger here so that it also runs at the beginning
    this.sendEvents()
  }

  // Stop the interval (will be called when user logs out or closed window or when not on tab)
  public stopTimer() {
    if (this._timerId) {
      clearInterval(this._timerId)
      this._timerId = null
    }
  }

  // Check if interval is running
  public isTimerRunning(): boolean {
    return Boolean(this._timerId)
  }

  // This needs to be an arrow function so that "this" is set to
  // the current context. If declared as a normal function,
  // "this" would refer to the window object, because
  // the function is called in "setInterval".
  private sendEvents = async (): Promise<undefined> => {
    if (!this._active) {
      return
    }

    // Do nothing when currently sending data
    if (this._isCurrentlySendingData) {
      return
    }

    // Check if there are events in the db to send
    const events = this.getEvents()
    if (events.length === 0) {
      return
    }

    // Get all events to send as batch
    // The splice function cuts out the first events up to '_limit'
    // and the remaining events stay in the 'events' object.
    const eventsToSend = events.splice(0, this._limit)
    this._isCurrentlySendingData = true

    try {
      const response = await fetch(this._url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          telemetries: eventsToSend,
        }),
      })
      // const response = { ok: true, statusText: "asdf" };

      // response.ok => when status code is between 200 - 299
      if (!response.ok) {
        // If response was not ok we send an error to Sentry. However
        // we continue with the normal flow to prevent sending
        // events multiple times.
        const errorMsg =
          'Failure sending events to Telemetrie: ' + response.statusText
        Sentry.captureMessage(errorMsg)
      }
    } catch (error) {
      Sentry.captureMessage(
        'Error sending events to Telemetrie: ' + (error as Error).message
      )
    }

    // If sending of data was successful we
    // store the remaining events back to
    // local storage.
    this.setEvents(events)

    // If there are still events in the db then
    // immediately rerun the sending function
    // and dont wait until the next call.
    this._isCurrentlySendingData = false
    if (events.length > 0) {
      return this.sendEvents()
    }
  }
}

const telemetrie = new Telemetrie()

export { Telemetrie, telemetrie }
