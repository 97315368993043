import { useQuery } from '@apollo/client'
import {
  FeatureEnum,
  Occurrence,
  ProductInternationalData,
  ProductVerlaufData,
  type EntityDataUnion,
  type EntitySearchResultInterface,
  type ProductData,
  type SearchFilter,
  type SearchResultTotalInterface,
} from '@austria-codex/types'
import {
  isNullOrUndefined,
  isProduct,
  isProductIdentifier,
  isProductInternational,
  isProductInternationalIdentifier,
  isProductVerlauf,
  isProductVerlaufIdentifier,
} from '@austria-codex/utilities'
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import FilterIcon from '@mui/icons-material/FilterList'
import { CircularProgress, Box as MuiBox } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import classNames from 'classnames'
import React, {
  useCallback,
  useRef,
  useState,
  type KeyboardEvent,
  type MouseEvent,
  type ReactNode,
} from 'react'
import { useDebounce } from 'use-debounce'
import { SEARCH_QUERY } from '../../api/search'
import OutOfStockIcon from '../../assets/images/ic-out-of-stock.svg?react'
import { getBox, isType2Or3Product } from '../../helpers/product.helper'
import { useBundleFeature } from '../../hooks/useBundleFeature'
import { useFocusWithin } from '../../hooks/useFocusWithing'
import { useAppDispatch, useAppSelector } from '../../hooks/useStoreHooks'
import { telemetrie } from '../../services/telemetrie.service'
import {
  entityIdentifiersSelector,
  fetchEntitiesWithRelatedData,
} from '../../store/entities'
import { filterSelector } from '../../store/filter'
import { setQuery } from '../../store/search'
import { setSetting } from '../../store/settings.store'
import { entityIdentifiersToEntityPartials } from '../../utilities/entity'
import { closestUnselectedIndex } from '../../utilities/search'
import { EntityCircleIcon } from '../EntityIcon/EntityCircleIcon'
import { DispensaryIcon } from '../Icons/DispensaryIcon'
import { ReimbursementBoxIcon } from '../Icons/ReimbursementBoxIcon'
import { Box, Flex } from '../Layout/Box'
import { Text } from '../Layout/Typography'
import { Numeral, PRICE_FORMAT } from '../Utility/Numeral'
import { RoutesOfAdministrationToolbar } from './FiltersToolbar'
import { ProductVerlaufDialog } from './ProductVerlaufDialog'
import { SearchInput } from './SearchInput'

enum Key {
  Enter = 'Enter',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
}

type TSearchQueryResult = { search: EntitySearchResultInterface }
type TSearchQueryVariables = {
  query: string
  filter: SearchFilter
}

export function SearchField() {
  const [focus, focusWithinHandler, blur] = useFocusWithin()
  const [loadingMore, setLoadingMore] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const hasAdditionalInformation = useBundleFeature(
    FeatureEnum.SearchBarAdditionalInformation
  )
  const hasFilter = useBundleFeature(FeatureEnum.SearchBarFilter)
  const { selectedRoutesOfAdministration } = useAppSelector(filterSelector)
  const selectedEntityIdentifiers = useAppSelector(entityIdentifiersSelector)
  const includesVeterinary = useAppSelector(
    (state) => state.settings.includeVeterinary
  )
  const includesRevokedAuthorizations = useAppSelector(
    (state) => state.settings.includeRevokedAuthorizations
  )
  const includesOutOfStockProducts = useAppSelector(
    (state) => state.settings.includeOutOfStockProducts
  )

  const list = useRef<HTMLUListElement>(null)
  const input = useRef<HTMLInputElement>(null)

  const dispatch = useAppDispatch()
  const query = useAppSelector((state) => state.search.query)

  const [debouncedQuery] = useDebounce(query, 500)

  const { loading, data, fetchMore } = useQuery<
    TSearchQueryResult,
    TSearchQueryVariables
  >(SEARCH_QUERY, {
    variables: {
      query: debouncedQuery,
      filter: {
        // In national mode we only want to search AT products
        isoCodes: ['at'],
        includeVeterinary: includesVeterinary,
        includeVerlaufsTyp5: includesRevokedAuthorizations,
        includeOutOfStockProducts: includesOutOfStockProducts,
        routesOfAdministration: selectedRoutesOfAdministration,
      },
    },
    skip: debouncedQuery.length < 3,
  })

  function onChangeQuery(query: string) {
    dispatch(setQuery({ query, hitAdded: false }))
  }

  const onAddEntry = useCallback(
    (id: string, name: string) => {
      const ids = entityIdentifiersToEntityPartials([id])
      // 2 Redux-Action-Creators
      dispatch(setQuery({ query: '', hitAdded: true }))
      dispatch(fetchEntitiesWithRelatedData(ids))

      blur()

      if (input.current) {
        input.current.blur()
      }

      if (
        isProductIdentifier(id) ||
        isProductInternationalIdentifier(id) ||
        isProductVerlaufIdentifier(id)
      ) {
        telemetrie.searchDrugClicked(id, name)
      } else {
        telemetrie.searchSubstanceClicked(id, name)
      }
    },
    [blur, dispatch]
  )

  const onKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const key = event.key

      if (key === Key.Enter && data) {
        if (data.search.hits.length > 0) {
          const hit = data.search.hits[selectedIndex]
          if (hit) {
            onAddEntry(hit.id, hit.bezeichnung)
          }
        }
        return
      }

      if ((key === Key.ArrowUp || key === Key.ArrowDown) && data) {
        const reverse = key === Key.ArrowUp

        const nextSelectedIndex = closestUnselectedIndex(
          selectedIndex,
          data.search.hits,
          selectedEntityIdentifiers,
          reverse
        )

        if (list.current) {
          const item = list.current.children.item(nextSelectedIndex)
          if (item) {
            item.scrollIntoView({ block: 'nearest' })
          }
        }

        setSelectedIndex(nextSelectedIndex)
      }
    },
    [data, selectedIndex, onAddEntry, selectedEntityIdentifiers]
  )

  const onClickMore = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      input.current?.focus()

      setLoadingMore(true)

      const offset = data?.search.offset

      fetchMore({
        variables: {
          query: debouncedQuery,
          // The first search request returns the top suggestions with an 'offset' of 'null'.
          // So for the first 'fetchMore', the offset is 0, after that, the 'offset' is
          // the returned value + the next 30.
          offset: isNullOrUndefined(offset) ? 0 : offset + 30,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult
          }

          return {
            search: {
              ...fetchMoreResult.search,
              hits: [
                ...previousResult.search.hits,
                ...fetchMoreResult.search.hits,
              ],
            },
          }
        },
      }).finally(() => {
        // Need to use timeout here to wait for the new elements to be rendered.
        setTimeout(() => {
          if (list && list.current) {
            const scrollTop = list.current.scrollTop
            list.current.scrollTo({ top: scrollTop + 32, behavior: 'smooth' })
          }

          setLoadingMore(false)
        }, 200)
      })
    },
    [fetchMore, debouncedQuery, data]
  )

  const total = data
    ? data.search.total.products + data.search.total.substances
    : 0

  const showLoadingMoreBtn = data && total > data.search.hits.length

  return (
    <Box
      position="relative"
      minHeight={[showFilter ? 104 : 54, 54]}
      display="flex"
      flexGrow={1}
    >
      <Container
        open={focus}
        onKeyDown={onKeyPress}
        borderRadius={[0, 3]}
        {...focusWithinHandler}
      >
        <Flex minHeight="54px" flexWrap={['wrap', 'nowrap']} flex={'0 0 auto'}>
          {hasFilter && (
            <FilterToolBar flexBasis={['100%', 0]} open={showFilter}>
              <RoutesOfAdministrationToolbar />
            </FilterToolBar>
          )}
          <Flex flex={1}>
            {hasFilter && (
              <>
                <Flex display={['flex', 'none']}>
                  <IconButton
                    color="primary"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterIcon />
                  </IconButton>
                </Flex>
                <Flex width="2px" my="12px" bg="primary.main" />
              </>
            )}
            <SearchInput ref={input} value={query} onChange={onChangeQuery} />
          </Flex>
        </Flex>
        <Dropdown show={focus}>
          {query.length === 0 ? null : loading ? (
            <Status large>Daten werden geladen...</Status>
          ) : query.length < 3 ? (
            <Status>Suchbegriff muss länger sein.</Status>
          ) : data?.search?.hits?.length === 0 ? (
            <Status>Keine Ergebnisse gefunden.</Status>
          ) : (
            <>
              <Hits ref={list}>
                {data?.search?.hits.map((entity, index) => (
                  <Hit
                    key={entity.id}
                    entity={entity}
                    disabled={selectedEntityIdentifiers.includes(entity.id)}
                    selected={selectedIndex === index}
                    onHover={() => setSelectedIndex(index)}
                    onAdd={onAddEntry}
                    showAdditionalInformation={hasAdditionalInformation}
                  />
                ))}
              </Hits>
              <ListMetaItem>
                <Meta total={data?.search?.total}>
                  <MuiBox
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      width: 120,
                    }}
                  >
                    {loadingMore ? (
                      <CircularProgress size={20} thickness={4} />
                    ) : showLoadingMoreBtn ? (
                      <MoreButton onClick={onClickMore}>
                        Mehr anzeigen
                      </MoreButton>
                    ) : null}
                  </MuiBox>
                </Meta>
              </ListMetaItem>
            </>
          )}
        </Dropdown>
      </Container>
    </Box>
  )
}

const ListMetaItem = styled.li`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

type FilterToolbarProps = {
  open: boolean
}

const FilterToolBar = styled(Flex)<FilterToolbarProps>`
  display: ${(props) => (props.open ? 'flex' : 'none')};

  ${(props) => props.theme.up(1, props.theme.breakpoints)} {
    display: flex;
    flex: 0 0 auto;
  }
`

type DropdownProps = {
  show: boolean
}

const Dropdown = styled.div<DropdownProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
`

type MetaProps = {
  total: SearchResultTotalInterface | undefined
  children: ReactNode
}

function Meta({ total, children }: MetaProps) {
  const hasProducts = total && total.products > 0
  const hasSubstances = total && total.substances > 0
  const theme = useTheme()

  return (
    <MuiBox
      sx={{
        alignItems: 'left',
        display: 'flex',
        justifyContent: 'space-between',
        height: 'auto',
        px: 2,
        py: 1,
        lineHeight: 2,
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
      css={css`
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top: 1px solid ${theme.palette.grey[200]};
        background: ${theme.palette.grey[50]};
      `}
    >
      <MuiBox sx={{ alignItems: 'center', columnGap: 1, display: 'flex' }}>
        <MuiBox>
          {!hasProducts && !hasSubstances ? (
            <Text fontStyle="italic">Es wurden keine Treffer gefunden.</Text>
          ) : (
            <Text>
              {hasProducts && (
                <>
                  <Text fontWeight="bold">{total.products}</Text> Produkte
                </>
              )}
              {hasProducts && hasSubstances && ' und '}
              {hasSubstances && (
                <>
                  <Text fontWeight="bold">{total.substances}</Text> Stoffe
                </>
              )}
            </Text>
          )}
        </MuiBox>
        {children}
      </MuiBox>
      <OutOfStockCheckbox />
    </MuiBox>
  )
}

function OutOfStockCheckbox() {
  const dispatch = useAppDispatch()
  const { includeOutOfStockProducts } = useAppSelector(
    (state) => state.settings
  )

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(
      setSetting({
        key: 'includeOutOfStockProducts',
        value: event.target.checked,
      })
    )
  }

  return (
    <MuiBox sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      <Text>Nicht lieferbare Produkte anzeigen</Text>
      <Checkbox
        sx={{ padding: 0 }}
        checked={includeOutOfStockProducts}
        onChange={handleCheckboxChange}
      />
    </MuiBox>
  )
}

type ContainerProps = {
  open: boolean
}

const Container = styled(Box)<ContainerProps>(({ theme, open }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  zIndex: 10000,
  background: open ? 'white' : theme.palette.primary.light,
  boxShadow: open ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none',
  transition: 'background-color 0.3s, box-shadow 0.3s ease-out',
}))

type StatusProps = {
  large?: boolean
}

const Status = styled.div<StatusProps>`
  align-items: center;
  display: flex;
  font-style: italic;
  justify-content: center;
  padding: ${(props) => props.theme.spacing(2, props.theme.space)};

  ${(props) =>
    props.large &&
    css`
      height: ${64 * 5}px;
      height: ${64 * 5}px;
    `}
`

const MoreButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  font-weight: bold;
`

const Hits = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  height: ${64 * 5}px;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`

type HitProps = {
  entity: EntityDataUnion
  disabled: boolean
  selected: boolean
  onHover: () => void
  onAdd: (identifier: string, name: string) => void
  showAdditionalInformation?: boolean
}

function Hit({
  entity,
  disabled,
  selected,
  onHover,
  onAdd,
  showAdditionalInformation,
}: HitProps) {
  const mode = useAppSelector((state) => state.user.mode)

  const [dialogOpen, setDialogOpen] = useState(false)

  function handleHover() {
    if (disabled) return
    onHover()
  }

  function handleClick() {
    if (disabled || dialogOpen) return

    if (isProductVerlauf(entity)) {
      setDialogOpen(true)
      return
    }

    onAdd(entity.id, entity.bezeichnung)
  }

  const isProd =
    isProduct(entity) ||
    isProductInternational(entity) ||
    isProductVerlauf(entity)
  const isSub = !isProd

  const classes = classNames({
    product: isProd,
    substance: isSub,
  })

  const showCountryBadge = isProd && mode === 'international'

  return (
    <li>
      <EntryLink
        disabled={disabled}
        selected={selected}
        className={classes}
        onMouseEnter={handleHover}
        onClick={handleClick}
      >
        <Flex marginRight={3}>
          <EntityCircleIcon entity={entity} size="small" />
        </Flex>
        <Flex flexWrap={['wrap', 'nowrap']} flex="1">
          <MuiBox
            sx={{ alignItems: 'center', display: 'flex', flexGrow: 1, gap: 1 }}
            className="tracking-search-result"
          >
            <Text textAlign="left">{entity.bezeichnung}</Text>
            {isProduct(entity) && entity.suffix && (
              <Text fontStyle="italic"> {entity.suffix}</Text>
            )}
          </MuiBox>
          {showAdditionalInformation && (
            <Flex
              marginLeft={[0, 3]}
              flex="0 0 auto"
              alignItems="center"
              flexDirection={['row-reverse', 'row']}
            >
              {showCountryBadge && <Countries product={entity} />}
              {isProduct(entity) && <ProductHit product={entity} />}
              {isProductVerlauf(entity) && <Text>Zulassung aufgehoben</Text>}
            </Flex>
          )}
        </Flex>
      </EntryLink>
      {dialogOpen && (
        <ProductVerlaufDialog
          open={dialogOpen}
          handleClose={() => {
            setDialogOpen(false)
          }}
          entity={entity as ProductVerlaufData}
        />
      )}
    </li>
  )
}

type ProductHitProps = {
  product: ProductData
}

function ProductHit({ product }: ProductHitProps) {
  const box = getBox(product.abgabe?.ekoBoxen)
  const type2Or3Product = isType2Or3Product(product.ausVerlauf)

  return (
    <>
      {!type2Or3Product && product.istLieferbar === Occurrence.No && (
        <Flex mx={2} alignItems="center" justifyContent="center">
          <OutOfStockIcon />
        </Flex>
      )}
      {type2Or3Product && (
        <Flex mx={1} alignItems="center" justifyContent="center">
          <Text>nicht im Handel</Text>
        </Flex>
      )}
      {!type2Or3Product &&
        product?.verkauf?.preisAb &&
        product?.verkauf?.anzahlArtikel && (
          <Box ml={2} mr={1}>
            <Price
              price={
                box
                  ? product.verkauf.preisAb.preisKVP
                  : product.verkauf.preisAb.preisUVP
              }
              praefix={product.verkauf.anzahlArtikel > 1 ? 'ab ' : ''}
              suffix={box ? ' KVP' : ' UVP'}
            />
          </Box>
        )}
      {box && (
        <Box ml={1}>
          <ReimbursementBoxIcon type={box} />
        </Box>
      )}
      {!type2Or3Product && product.abgabe?.art && (
        <Box ml={[0, 1]}>
          <DispensaryIcon type={product.abgabe?.art} />
        </Box>
      )}
    </>
  )
}

type PriceProps = {
  price: number | undefined
  praefix?: string
  suffix?: string
}

function Price({ price, praefix, suffix }: PriceProps) {
  return price ? (
    <Text fontStyle="italic">
      {praefix}
      <Numeral input={price} format={PRICE_FORMAT} />
      {suffix}
    </Text>
  ) : null
}

type EntryLinkProps = {
  selected: boolean
  disabled: boolean
}

const EntryLink = styled.button<EntryLinkProps>`
  align-items: center;
  background: white;
  border: none;
  cursor: pointer;
  display: flex;
  min-height: 64px;
  padding: ${(props) => props.theme.spacing(1, props.theme.space)}
    ${(props) => props.theme.spacing(3, props.theme.space)};
  width: 100%;

  & + & {
    border-top: 1px solid transparent;
  }

  &.product + &.substance,
  &.substance + &.product {
    border-color: ${(props) => props.theme.palette.grey[200]};
  }

  ${(props) =>
    props.disabled &&
    css`
      background: ${props.theme.palette.grey[200]};
    `}

  ${(props) =>
    props.selected &&
    !props.disabled &&
    css`
      background: ${props.theme.palette.primary.light};
      cursor: pointer;
    `}
`

type TCountriesProps = {
  product: ProductData | ProductInternationalData | ProductVerlaufData
}

function Countries({ product }: TCountriesProps) {
  // Merge the iso code of the product with the iso codes of other countries if set.
  const isoCodes = [product.isoCode]

  return (
    <Flex>
      {isoCodes.map((isoCode) => (
        <CountryBadge key={isoCode} title={`Ländercode: ${isoCode}`}>
          {isoCode}
        </CountryBadge>
      ))}
    </Flex>
  )
}

const CountryBadge = styled.span`
  align-items: center;
  background-color: #d9d9d9;
  border: 1px solid white;
  border-radius: 12px;
  color: #212121;
  display: flex;
  font-size: 8px;
  height: 24px;
  justify-content: center;
  width: 24px;
`
