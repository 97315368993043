import { useMediaQuery, useTheme } from '@mui/material'
import Pagination from '@mui/material/Pagination'

export const ITEMS_PER_PAGE = 4

export type CardsPaginationProps = {
  totalCount: number
  setPage: (page: number) => void
  sectionRef: React.RefObject<HTMLDivElement>
  currentPage: number
}

export function CardsPagination({
  totalCount,
  setPage,
  sectionRef,
  currentPage,
}: CardsPaginationProps) {
  const theme = useTheme()
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.between('xs', 'md'))

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setPage(value)
    // Scroll to cardSection-top on mobile devices
    // requestAnimationFrame ensures that we scroll after rendering
    // (to avoid problems with different container heights on last page)
    if (isMobileOrTablet) {
      requestAnimationFrame(() => {
        sectionRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      })
    }
  }

  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE)

  return (
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={handlePageChange}
    />
  )
}
