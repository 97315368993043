import { Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useEffect, useMemo, useRef, useState } from 'react'
import { getPaginatedArticles } from '../../helpers/media-item.helper'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { OeazArticle } from '../../types/media-item.types'
import { ArticleCard } from './ArticleCard'
import { CardsPagination } from './CardsPagination'

type ArticleCardsSectionProps = {
  bgColor?: string
  filteredArticles: OeazArticle[]
}

export function CardsSection({
  bgColor = 'common.white',
  filteredArticles,
}: ArticleCardsSectionProps) {
  const mediaFilter = useAppSelector((state) => state.mediaFilter)
  const [currentPage, setCurrentPage] = useState(1)
  const sectionRef = useRef<HTMLDivElement>(null)

  // Set current page to 1 when the filter changes
  useEffect(() => {
    setCurrentPage(1)
  }, [mediaFilter])

  // Calculates the items to be displayed (based on the selected page)
  const paginatedArticles = useMemo(
    () => getPaginatedArticles(filteredArticles, currentPage),
    [currentPage, filteredArticles]
  )

  const isSingleCard = filteredArticles.length === 1

  return (
    <Box
      ref={sectionRef}
      sx={{ backgroundColor: bgColor, px: { xs: 1, sm: 4 }, pt: 1, pb: 4 }}
    >
      {/* Cards */}
      <Grid
        container
        spacing={4}
        justifyContent="flex-start"
        alignItems="start"
      >
        {paginatedArticles.map((article) => (
          <Grid size={{ xs: 12, sm: isSingleCard ? 8 : 6 }} key={article.id}>
            <ArticleCard article={article} />
          </Grid>
        ))}
      </Grid>
      {/* Pagination */}
      {filteredArticles.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CardsPagination
            totalCount={filteredArticles.length}
            setPage={setCurrentPage} // Setting the current page
            sectionRef={sectionRef}
            currentPage={currentPage}
          />
        </Box>
      )}
    </Box>
  )
}
