import { FeatureEnum, type TCountryMode } from '@austria-codex/types'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import { CircularProgress, Box as MuiBox, useMediaQuery } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, type ReactNode } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { ApplicationBar } from '../components/ApplicationBar/ApplicationBar'
import { IntModeSelector } from '../components/ApplicationBar/IntModeSelector'
import { EntityPillsList } from '../components/EntityPills/EntityPillsList'
import { Footer } from '../components/Footer/Footer'
import { Background } from '../components/Layout/Background'
import { Box } from '../components/Layout/Box'
import { Container } from '../components/Layout/Container'
import { Sheet } from '../components/Layout/Sheet'
import { MessageBox } from '../components/MessageBox/MessageBox'
import { LinkTabItem, Tabs } from '../components/Navigation/Tabs'
import { ScrollTop } from '../components/ScrollTop/ScrollTop'
import { SearchFieldInt } from '../components/SearchField/International/SearchFieldInt'
import { SearchField } from '../components/SearchField/SearchField'
import { PersonalCharacteristics } from '../features/components/Highlight/PersonalCharacteristics'
import { SafetyTabIndicator } from '../features/components/SafetyTabIndicator/SafetyTabIndicator'
import { getSubstanceIdsAndBezeichnung } from '../helpers/substance.helper'
import { useBundleFeature } from '../hooks/useBundleFeature'
import { useAppSelector } from '../hooks/useStoreHooks'
import { OeazArticle } from '../types/media-item.types'
import { Paths } from '../utilities/path.constants'
import { QueryKeys } from '../utilities/query-keys'
import { subscribeToEntityHistory } from '../utilities/url'
import { AlternativesPage } from './Main/AlternativesPage'
import { ApplicationPage } from './Main/ApplicationPage'
import { ColoredDot, MediaPage } from './Main/MediaPage'
import { RetailPage } from './Main/RetailPage'
import { SafetyPage } from './Main/SafetyPage'

const modeToSearchField: Record<TCountryMode, ReactNode> = {
  national: <SearchField />,
  international: <SearchFieldInt />,
}

export function ResponsiveLinkTabItem() {
  return (
    <LinkTabItem to={Paths.Media} trackingName="beitraege">
      <AutoStoriesIcon sx={{ color: 'primary.dark' }} fontSize="small" />
    </LinkTabItem>
  )
}

export function MainPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const hasSearchBar = useBundleFeature(FeatureEnum.SearchBar)
  const hasInternational = useBundleFeature(FeatureEnum.International)
  const hasPersonalCharacteristics = useBundleFeature(
    FeatureEnum.PersonalCharacteristics
  )
  const hasEntityPills = useBundleFeature(FeatureEnum.Pills)
  const hasFooter = useBundleFeature(FeatureEnum.Footer)
  const hasSafetyIndicator = useBundleFeature(FeatureEnum.SafetyIndicator)
  const mode = useAppSelector((state) => state.user.mode)
  const entities = useAppSelector((state) => state.entities.entities)
  const selectedSubstance = useAppSelector(
    (state) => state.mediaFilter.selectedSubstance
  )
  const isSmallScreen = useMediaQuery('(max-width:414px)')

  useEffect(() => {
    const unsubscribe = subscribeToEntityHistory(location, navigate)
    return () => unsubscribe()
  }, [location, navigate])

  const extractedSubstances = useMemo(() => {
    const ids = getSubstanceIdsAndBezeichnung(entities).map(
      (substance) => substance.id
    )
    return ids
  }, [entities])

  //TODO: better query key to avoid unneseccary fetches when a new entity is added
  const { status, data: oeazArticles } = useQuery({
    queryKey: [
      QueryKeys.OeazArticles,
      { extractedSubstances },
      { selectedSubstance },
    ],
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    queryFn: async ({ queryKey }) => {
      const [, { extractedSubstances }] = queryKey
      const substances = selectedSubstance
        ? [selectedSubstance]
        : extractedSubstances

      if (substances.length === 0) {
        return []
      }

      const response = await fetch(
        'https://data-connect.apoverlag.at/api/oeaz/articles_by_wirkstoff/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            token:
              'srw5DfktTf8zipCZhUmjgtJxSqJ7bIfiSDqzh6lUyrFCaizyHPQZrwxZT6uexZho',
            query: substances,
            include_sibling_substances: true,
            amount: 100,
          }),
        }
      )

      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`)
      }

      const result: OeazArticle[] = await response.json()

      return result
    },
  })

  const searchField = hasInternational
    ? modeToSearchField[mode]
    : modeToSearchField['national']

  return (
    <>
      <ApplicationBar>
        {hasInternational && <IntModeSelector />}
        {hasSearchBar && searchField}
      </ApplicationBar>
      {hasPersonalCharacteristics && mode === 'national' && (
        <Background>
          <Container>
            <PersonalCharacteristics />
          </Container>
        </Background>
      )}
      {hasEntityPills && (
        <Background>
          <Container>
            <Box py={3}>
              <EntityPillsList />
            </Box>
          </Container>
        </Background>
      )}
      <Background>
        <MessageBox />
      </Background>
      <Background pb={7} mb={-7} />
      <Container>
        <Box mb={4}>
          <Sheet>
            <Tabs>
              <LinkTabItem to={Paths.Index} trackingName="anwendung">
                Anwendung
              </LinkTabItem>
              <LinkTabItem
                to={Paths.Safety}
                metaRender={
                  hasSafetyIndicator ? <SafetyTabIndicator /> : undefined
                }
                trackingName="sicherheit"
              >
                Sicherheit
              </LinkTabItem>
              <LinkTabItem to={Paths.Retail} trackingName="handel">
                Handel
              </LinkTabItem>
              <LinkTabItem to={Paths.Alternatives} trackingName="alternativen">
                Alternativen
              </LinkTabItem>
              {isSmallScreen ? (
                <ResponsiveLinkTabItem />
              ) : (
                <LinkTabItem to={Paths.Media} trackingName="beitraege">
                  <MuiBox sx={{ position: 'relative' }}>
                    <MuiBox sx={{ position: 'absolute', left: '-14px' }}>
                      {status === 'pending' ? (
                        <CircularProgress size={10} />
                      ) : status === 'success' && oeazArticles.length > 0 ? (
                        <ColoredDot />
                      ) : null}
                    </MuiBox>
                    Beiträge
                  </MuiBox>
                </LinkTabItem>
              )}
            </Tabs>
            <Routes>
              <Route path={Paths.Index} element={<ApplicationPage />} />
              <Route path={Paths.Safety} element={<SafetyPage />} />
              <Route path={Paths.Retail} element={<RetailPage />} />
              <Route path={Paths.Alternatives} element={<AlternativesPage />} />
              <Route
                path={Paths.Media}
                element={
                  <MediaPage
                    oeazArticles={oeazArticles ?? []}
                    status={status}
                  />
                }
              />
            </Routes>
          </Sheet>
        </Box>
      </Container>
      <Container>
        <Box as="footer" px={4} mt={110} paddingBottom={4}>
          {hasFooter && <Footer />}
        </Box>
      </Container>
      <ScrollTop />
    </>
  )
}
