import { Box } from '@mui/material'
import type { ReactNode } from 'react'
import { Content } from '../Layout/Content'
import { Heading } from '../Layout/Typography'

type MediaSectionHeaderProps = {
  title: string
  disabled?: boolean
  children?: ReactNode
  bgColor?: string
  height?: string
  subtitle?: string
}

export function MediaSectionHeader({
  title,
  disabled = false,
  children,
  bgColor = 'common.white',
  height = '100px',
  subtitle,
}: MediaSectionHeaderProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: bgColor,
        minHeight: height,
      }}
    >
      <Content width="large">
        <Box alignItems="center" justifyContent="space-between" py={2}>
          <Heading color={disabled ? 'grey.500' : 'grey.800'} level={2}>
            {title}
          </Heading>
          <Box> {subtitle}</Box>
          {children && children}
        </Box>
      </Content>
    </Box>
  )
}
