import { Box } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { getProducts, getSubstances } from '../../helpers/entity.helper'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { telemetrie } from '../../services/telemetrie.service'
import { MediaCategory, OeazArticle } from '../../types/media-item.types'
import { Anchor } from '../Layout/Anchor'

export function CategoryChip({ category }: { category: MediaCategory }) {
  return <Chip label={category} size="small" sx={{ borderRadius: '8px' }} />
}

export function SourceLogo({ logo }: { logo: string }) {
  return (
    <Box
      component="img"
      src="https://www.oeaz.at/templates/oeaz-logo.svg"
      alt="Logo"
      sx={{
        height: 'auto',
        width: 'auto',
        maxWidth: 120,
        objectFit: 'contain',
      }}
    ></Box>
  )
}

export function RelevanceChip({ relevance }: { relevance: string }) {
  return (
    <Chip
      label={`Relevanz: ${relevance}`}
      size="small"
      sx={{
        backgroundColor: 'primary.light',
        color: 'primary.dark',
        borderRadius: '8px',
      }}
    />
  )
}

export function YearChip({ publicationDate }: { publicationDate: string }) {
  return (
    <Chip label={publicationDate} size="small" sx={{ borderRadius: '8px' }} />
  )
}

type ArticleCardsProps = {
  article: OeazArticle
}

export function ArticleCard({ article }: ArticleCardsProps) {
  const entities = useAppSelector((state) => state.entities.entities)

  const {
    article_url,
    authorized_url,
    id,
    pub_date,
    relevance,
    rubrik,
    summary,
    title,
    logo,
    for_substance_id,
  } = article

  const pubYear = pub_date.slice(0, 4)

  function handleOnLinkClick() {
    const items = Object.values(entities)

    const searchedSubstances = getSubstances(items).map((i) => ({
      id: i.id,
      name: i.bezeichnung,
    }))

    const searchedProducts = getProducts(items).map((i) => ({
      id: i.id,
      name: i.bezeichnung,
    }))

    telemetrie.articleClicked(
      'oeaz',
      id,
      title,
      for_substance_id,
      pubYear,
      rubrik,
      relevance,
      searchedSubstances,
      searchedProducts
    )
  }

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: 3,
        borderRadius: 2,
        overflow: 'hidden',
        p: 2,
      }}
    >
      <CardContent sx={{ flex: 1, p: 0 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <CategoryChip category={rubrik} />
          <SourceLogo logo={logo} />
        </Box>
        <Box sx={{ height: 170, mt: 3 }}>
          <Typography
            gutterBottom
            variant="h5"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 6,
            }}
          >
            {summary}
          </Typography>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2,
          p: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <RelevanceChip relevance={relevance} />
          <YearChip publicationDate={pubYear} />
        </Box>
        <Box sx={{ whiteSpace: 'nowrap' }}>
          <Anchor
            target="_blank"
            rel="noopener noreferrer"
            href={authorized_url || article_url}
            onClick={handleOnLinkClick}
          >
            Jetzt lesen
          </Anchor>
        </Box>
      </CardActions>
    </Card>
  )
}
