import {
  EntityDataUnion,
  ProductUnion,
  SubstanceData,
  SubstanceInternationalData,
} from '@austria-codex/types'
import {
  isProduct,
  isProductInternational,
  isProductVerlauf,
  isSubstance,
  isSubstanceInternational,
} from '@austria-codex/utilities'

export function getSubstances(entities: EntityDataUnion[]) {
  return (
    entities.filter(
      (h): h is SubstanceData | SubstanceInternationalData =>
        isSubstance(h) || isSubstanceInternational(h)
    ) ?? []
  )
}

export function getProducts(entities: EntityDataUnion[]) {
  return (
    entities.filter(
      (h): h is ProductUnion =>
        isProduct(h) || isProductInternational(h) || isProductVerlauf(h)
    ) ?? []
  )
}
