import { Box } from '@mui/material'
import { useMemo } from 'react'
import { CardsSection } from '../../components/MediaSections/CardsSection'
import {
  FilterPanel,
  MediaFilter,
} from '../../components/MediaSections/FilterPanel'
import { MediaSectionHeader } from '../../components/MediaSections/MediaSectionHeader'
import { SubstancePills } from '../../components/MediaSections/SubstancePills'
import { AnchorMenu, AnchorProvider } from '../../components/Navigation/Anchor'
import { getSubstanceIdsAndBezeichnung } from '../../helpers/substance.helper'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { OeazArticle } from '../../types/media-item.types'

type MediaPageProps = { oeazArticles: OeazArticle[]; status: string }

export function MediaPage({ oeazArticles, status }: MediaPageProps) {
  const entities = useAppSelector((state) => state.entities.entities)
  const mediaFilter = useAppSelector((state) => state.mediaFilter)
  const substances = getSubstanceIdsAndBezeichnung(entities)

  // filter oeazArticles by date and relevance
  const filteredArticles = useMemo(() => {
    return oeazArticles.filter((article) => {
      const matchesPubDate = mediaFilter.pub_date.includes(
        article.pub_date.substring(0, 4)
      )
      const matchesRelevance = mediaFilter.relevance.includes(article.relevance)
      return matchesPubDate && matchesRelevance
    })
  }, [oeazArticles, mediaFilter])

  const hasArticles = filteredArticles.length > 0

  // make a unique array of all categories that are found in oeazArticles
  const categoriesString = useMemo(() => {
    return Array.from(
      new Set(filteredArticles.map((article) => article.rubrik))
    ).join(', ')
  }, [filteredArticles])

  function getTitle() {
    if (status === 'pending') return 'Suche Artikel...'
    if (!hasArticles) return 'Keine Artikel gefunden'
    if (filteredArticles.length === 100) return 'Top 100 Artikel'
    if (
      !mediaFilter.selectedSubstance &&
      substances.length > 1 &&
      filteredArticles.length === 40
    )
      return 'Top 40 Artikel'
    return `${filteredArticles.length} Artikel`
  }

  return (
    <AnchorProvider>
      {/* Box is used only to create space between the LinkTabItem and the FilterPanel */}
      {/* to be removed when more Media-Types like Podcasts are added */}
      <Box height={'42px'} borderBottom="2px solid #eeeeee"></Box>
      <AnchorMenu />
      <FilterPanel title="Relevante Beiträge zu den gewählten Wirkstoffen">
        <MediaFilter />
      </FilterPanel>
      {substances.length > 0 && <SubstancePills substances={substances} />}
      <MediaSectionHeader
        title={getTitle()}
        subtitle={
          status === 'pending'
            ? undefined
            : hasArticles
              ? categoriesString
              : 'Zur vorliegenden Wirkstoffauswahl und den gewählten Filteroptionen wurden keine relevanten Artikel gefunden.'
        }
      >
        {/* add when more Media-Types besides Artikel are added */}
        {/* <AnchorTab name="Artikel" hash="oeaz-artikel" position={0} /> */}
      </MediaSectionHeader>
      {hasArticles && status === 'success' && (
        <CardsSection filteredArticles={filteredArticles} />
      )}
    </AnchorProvider>
  )
}

export function ColoredDot() {
  return (
    <Box
      sx={{
        width: '10px',
        height: '10px',
        borderRadius: '5px',
        backgroundColor: 'primary.dark',
        display: 'inline-block',
      }}
    />
  )
}
