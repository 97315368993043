import FilterListIcon from '@mui/icons-material/FilterList'
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Popper,
  styled,
  Tooltip,
} from '@mui/material'
import Badge, { BadgeProps } from '@mui/material/Badge'
import { useMemo, useState, type ReactNode } from 'react'
import CloseIcon from '../../assets/images/delete.svg?react'
import { getYearsRange } from '../../helpers/media-item.helper'
import { useAppDispatch, useAppSelector } from '../../hooks/useStoreHooks'
import {
  initialFilterSettings,
  resetFilterSettings,
  toggleOlderYears,
  togglePubDate,
  toggleRelevance,
} from '../../store/mediaFilter.store'
import { Content } from '../Layout/Content'
import { Heading } from '../Layout/Typography'

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.primary.light}`,
    padding: '0 4px',
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.info.dark,
  },
}))

const { firstFiveYears } = getYearsRange()

export function MediaFilter() {
  const mediaFilter = useAppSelector((state) => state.mediaFilter)
  const dispatch = useAppDispatch()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'filter-popper' : undefined

  // Badge Counter
  // we count the unchecked checkboxes to display the number in the badge
  const uncheckedCount = useMemo(() => {
    let uncheckedYears = 0
    let uncheckedRelevance = 0

    // counts unchecked years
    firstFiveYears.forEach((year) => {
      if (!mediaFilter.pub_date.includes(year)) {
        uncheckedYears++
      }
    })
    // if "older" is unchecked count it as 1
    if (!mediaFilter.includeOlderYears) {
      uncheckedYears++
    }

    initialFilterSettings.relevance.forEach((rel) => {
      if (!mediaFilter.relevance.includes(rel)) {
        uncheckedRelevance++
      }
    })

    return uncheckedYears + uncheckedRelevance
  }, [mediaFilter])

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  function handleClickAway() {
    setAnchorEl(null)
  }

  function resetCheckboxes() {
    dispatch(resetFilterSettings())
  }

  return (
    <>
      <Tooltip
        title={
          uncheckedCount === 1
            ? `${uncheckedCount} Filteroption deaktiviert`
            : uncheckedCount > 1
              ? `${uncheckedCount} Filteroptionen deaktiviert`
              : ''
        }
        placement="top-end"
      >
        <FilterButton onClick={handleClick}>
          <StyledBadge badgeContent={uncheckedCount} variant="standard">
            <FilterListIcon sx={{ color: 'primary.dark', mr: '2px' }} />
            Filter
          </StyledBadge>
        </FilterButton>
      </Tooltip>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            sx={{
              p: 4,
              pb: 2,
              width: { xs: '100%', sm: 'auto' },
              color: 'grey.800',
              position: 'relative',
            }}
            elevation={3}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
              onClick={handleClick}
            >
              <CloseIcon />
            </IconButton>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto auto',
                gap: { xs: 1, sm: 2 },
              }}
            >
              {/* Years */}
              <Box>
                <Box sx={{ fontSize: '1rem', color: 'grey.500' }}>Jahre</Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <FormGroup>
                    {firstFiveYears.map((year) => (
                      <FormControlLabel
                        key={year}
                        label={year}
                        control={
                          <Checkbox
                            value={year}
                            onChange={() => dispatch(togglePubDate(year))}
                            checked={mediaFilter.pub_date.includes(year)}
                          />
                        }
                      />
                    ))}
                    <FormControlLabel
                      label="älter"
                      control={
                        <Checkbox
                          id="includeOlderYears"
                          value="older"
                          onChange={() => dispatch(toggleOlderYears())}
                          checked={mediaFilter.includeOlderYears === true}
                        />
                      }
                    />
                  </FormGroup>
                </Box>
              </Box>
              {/* Relevance */}
              <Box>
                <Box
                  sx={{
                    fontSize: '1rem',
                    color: 'grey.500',
                  }}
                >
                  Relevanz
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      label="Sehr Hoch"
                      control={
                        <Checkbox
                          value="sehr hoch"
                          onChange={() =>
                            dispatch(toggleRelevance('sehr hoch'))
                          }
                          checked={mediaFilter.relevance.includes('sehr hoch')}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Hoch"
                      control={
                        <Checkbox
                          value="hoch"
                          onChange={() => dispatch(toggleRelevance('hoch'))}
                          checked={mediaFilter.relevance.includes('hoch')}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Mittel"
                      control={
                        <Checkbox
                          value="mittel"
                          onChange={() => dispatch(toggleRelevance('mittel'))}
                          checked={mediaFilter.relevance.includes('mittel')}
                        />
                      }
                    />
                  </FormGroup>
                </Box>
              </Box>
              {/* Source */}
              <Box>
                <Box sx={{ fontSize: '1rem', color: 'grey.500' }}>Quelle</Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <FormGroup>
                    <FormControlLabel
                      label="ÖAZ"
                      control={
                        <Checkbox
                          value="oeaz"
                          checked={mediaFilter.source.includes('oeaz')}
                          disabled
                        />
                      }
                    />
                    {/*<FormControlLabel
                    label="Clinicum"
                    control={
                      <Checkbox
                        value={'clinicum'}
                        onChange={handleChange}
                        checked={mediaFilter.source.includes('clinicum')}
                        disabled
                      />
                    }
                  />
                  <FormControlLabel
                    label="Andere Quellen"
                    control={
                      <Checkbox
                        value={'others'}
                        onChange={handleChange}
                        checked={mediaFilter.source.includes('others')}
                        disabled
                      />
                    }
                  />*/}
                  </FormGroup>
                </Box>
              </Box>
            </Box>

            {/* Action */}
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button onClick={resetCheckboxes}>Zurücksetzen</Button>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

type FilterPanelProps = {
  title: string
  disabled?: boolean
  children: ReactNode
}

export function FilterPanel({
  title,
  disabled = false,
  children,
}: FilterPanelProps) {
  return (
    <Box sx={{ py: 1, backgroundColor: 'primary.light' }}>
      <Content width="large">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <div>
            <Heading color={disabled ? 'grey.500' : 'grey.800'} level={3}>
              {title}
            </Heading>
          </div>
          {children}
        </Box>
      </Content>
    </Box>
  )
}

const FilterButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 2,
  fontSize: '0.8125rem',
  fontWeight: 'bold',
  letterSpacing: '0.05rem',
  textTransform: 'uppercase',
  color: theme.palette.primary.main,
}))
