import { Indicator } from '../../../components/Indicator/Indicator'
import { isSingleSubstanceAndHasInteractions } from '../../../helpers/substance.helper'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import { filteredEntitiesHaveHighlightSelector } from '../../../store/highlight'
import {
  filteredInteractions,
  filteredMultiSelectSelector,
} from '../../../store/safety'

export function SafetyTabIndicator() {
  const interactions = useAppSelector(filteredInteractions)
  const doubleDosing = useAppSelector(filteredMultiSelectSelector)
  const hasHighlight = useAppSelector(filteredEntitiesHaveHighlightSelector)
  const entities = useAppSelector((state) => state.entities.entities)

  if (
    isSingleSubstanceAndHasInteractions(entities) ||
    (interactions && interactions.length > 0) ||
    hasHighlight ||
    (doubleDosing !== null &&
      (doubleDosing.agentBased.length > 0 ||
        doubleDosing.atcCodeBased.length > 0))
  ) {
    return <Indicator />
  }
  return null
}
