import { ITEMS_PER_PAGE } from '../components/MediaSections/CardsPagination'
import { OeazArticle } from '../types/media-item.types'

export function mapRelevance(relevanceString: string): number {
  switch (relevanceString) {
    case 'sehr hoch':
      return 3
    case 'hoch':
      return 2
    case 'mittel':
      return 1
    default:
      throw new Error(`Unbekannte Relevance: ${relevanceString}`)
  }
}

export function getYearsRange() {
  const currentYear = new Date().getFullYear()
  const allYearsLength = currentYear - 2010

  const yearsRange = []
  for (let i = 0; i <= allYearsLength; i++) {
    const year = currentYear - i
    yearsRange.push(String(year))
  }

  const firstFiveYears = yearsRange.slice(0, 5)
  const olderYears = yearsRange.slice(5)
  return { yearsRange, firstFiveYears, olderYears }
}

// get paginated articles
export function getPaginatedArticles(
  filteredArticles: OeazArticle[],
  currentPage: number
) {
  const sortedArticles = filteredArticles.sort((a, b) => {
    return mapRelevance(b.relevance) - mapRelevance(a.relevance)
  })

  return sortedArticles.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  )
}
