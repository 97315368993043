import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { Config } from '../config'
import alertReducer from './alerts'
import atcCodeHitsReducer from './atcCodeHits'
import characteristicsReducer from './characteristics'
import collapseReducer from './collapse'
import entitiesReducer from './entities'
import filterReducer from './filter'
import interactionsFilterReducer from './interactionsFilter.store'
import mediaFilterReducer from './mediaFilter.store'
import { localStorageMiddleware } from './middleware/localStorage.middleware'
import paginationReducer from './pagination'
import personalCharacteristicsReducer from './personalCharacteristics'
import productCompositionReducer from './productComposition'
import safetyReducer from './safety'
import searchReducer from './search'
import settingsReducer from './settings.store'
import userReducer from './user.store'

const rootReducer = combineReducers({
  entities: entitiesReducer,
  user: userReducer,
  safety: safetyReducer,
  interactionsFilter: interactionsFilterReducer,
  mediaFilter: mediaFilterReducer,
  collapse: collapseReducer,
  productComposition: productCompositionReducer,
  pagination: paginationReducer,
  filter: filterReducer,
  search: searchReducer,
  settings: settingsReducer,
  alert: alertReducer,
  atcCodeHits: atcCodeHitsReducer,
  highlight: combineReducers({
    characteristics: characteristicsReducer,
    personalCharacteristics: personalCharacteristicsReducer,
  }),
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware),
  devTools: Config.debug.redux as boolean,
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export default store
export { store }
