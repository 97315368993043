import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/useStoreHooks'
import { toggleSubstance } from '../../store/mediaFilter.store'
import { Content } from '../Layout/Content'

const MediaSubstanceIcon = ({ color = '#52a472' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="1.6"
        transform="translate(0 1)"
      >
        <path d="M10 0L18.66 5 18.66 15 10 20 1.34 15 1.34 5z" />
        <circle cx="10" cy="10" r="2.667" />
      </g>
    </svg>
  )
}

// Pill-Komponente mit Highlight-Eigenschaft
export const Pill = styled(Box)<{ highlight?: boolean }>`
  background: ${(props) =>
    props.highlight
      ? props.theme.palette.primary.dark // Highlight Farbe
      : props.theme.palette.primary.light};
  color: ${(props) =>
    props.highlight
      ? props.theme.palette.secondary.contrastText
      : props.theme.palette.primary.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  height: 38px;
  border-radius: 8px;
  transition: background-color 0.3s;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`

Pill.defaultProps = {
  p: 2,
}

export const PillIcon = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`

export const PillButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export function SubstancePills({
  substances,
}: {
  substances: { id: string; bezeichnung: string }[]
}) {
  const dispatch = useAppDispatch()
  const selectedSubstance = useAppSelector(
    (state) => state.mediaFilter.selectedSubstance
  )
  // Nur wenn mehr als eine Substanz vorhanden ist, ist sie klickbar
  const isClickable = substances.length > 1

  return (
    <Box sx={{ py: 2, backgroundColor: 'common.white' }}>
      <Content width="large">
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {substances.map((substance) => (
            <Pill
              key={substance.id}
              highlight={selectedSubstance === substance.id} // Highlight, wenn Substanz ausgewählt
              onClick={
                isClickable
                  ? () => dispatch(toggleSubstance(substance.id))
                  : undefined
              }
            >
              <PillIcon>
                <MediaSubstanceIcon
                  color={
                    selectedSubstance === substance.id ? '#fff' : '#52a472'
                  }
                />
              </PillIcon>
              {substance.bezeichnung}
            </Pill>
          ))}
        </Box>
      </Content>
    </Box>
  )
}
