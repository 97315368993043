import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getYearsRange } from '../helpers/media-item.helper'

const { yearsRange, olderYears } = getYearsRange()

export type FilterSettings = {
  pub_date: string[]
  includeOlderYears: boolean
  relevance: string[]
  source: string[]
  selectedSubstance: string
}

// Initial + Reset State
export const initialFilterSettings: FilterSettings = {
  pub_date: yearsRange,
  includeOlderYears: true,
  relevance: ['sehr hoch', 'hoch', 'mittel'],
  source: ['oeaz'],
  selectedSubstance: '',
}

const mediaFilterSlice = createSlice({
  name: 'mediaFilter',
  initialState: initialFilterSettings,
  reducers: {
    toggleSubstance: (state, action: PayloadAction<string>) => {
      const substanceId = action.payload
      if (state.selectedSubstance.includes(substanceId)) {
        state.selectedSubstance = ''
      } else {
        state.selectedSubstance = substanceId
      }
    },
    removeSelectedSubstance: (state) => {
      state.selectedSubstance = ''
    },
    togglePubDate: (state, action: PayloadAction<string>) => {
      const year = action.payload
      if (state.pub_date.includes(year)) {
        state.pub_date = state.pub_date.filter((item) => item !== year)
      } else {
        state.pub_date.push(year)
      }
    },
    toggleRelevance: (state, action: PayloadAction<string>) => {
      const relevance = action.payload
      if (state.relevance.includes(relevance)) {
        state.relevance = state.relevance.filter((item) => item !== relevance)
      } else {
        state.relevance.push(relevance)
      }
    },
    toggleOlderYears: (state) => {
      if (state.includeOlderYears) {
        state.pub_date = state.pub_date.filter(
          (item) => !olderYears.includes(item)
        )
      } else {
        state.pub_date.push(...olderYears)
      }
      state.includeOlderYears = !state.includeOlderYears
    },
    resetFilterSettings: () => {
      return initialFilterSettings
    },
  },
})

export const {
  toggleSubstance,
  removeSelectedSubstance,
  togglePubDate,
  toggleRelevance,
  toggleOlderYears,
  resetFilterSettings,
} = mediaFilterSlice.actions
export default mediaFilterSlice.reducer
